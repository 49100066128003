<template>
  <div class="page-home">
    <div v-if="loading">
      Figuring out who you are...
    </div>
    <div v-else-if="error">
      Sorry, I wasn't able to figure out who you are for some reason. Refresh the page or ask about
      it
      <a
        href="https://discord.gg/hfdbVtUC"
        target="_blank"
      >in the Discord</a>.
    </div>
    <div v-else>
      <div class="greeting">
        <div class="greeting--header">
          <img
            class="profile-pic"
            :src="user.profile_image_url"
            alt=""
          >Hello {{ username }}!
        </div>
        <p>
          Welcome to Stream Hooks. This is a tool for doing things whenever
          things happen on your stream or channel. If you have any questions,
          ask them
          <a
            href="https://discord.gg/hfdbVtUC"
            target="_blank"
          >in the Discord</a>.
        </p>
      </div>
      <div>
        <h2>Stream Hooks</h2>
        <actions-list />
        <!-- <emotes-list /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import EmotesList from './components/emotes-list.vue';
import ActionsList from '../components/actions-list.vue';
import TWITCH_EVENTS from '../../util/twitch-events';
import axios from '../util/axios';

export default {
  name: 'PageHome',
  components: {
    ActionsList
    // EmotesList
  },
  data () {
    return {
      loading: true,
      error: null,
      subscriptions: [],
      events: TWITCH_EVENTS,
      user: null
    };
  },
  computed: {
    username () {
      return this.user?.display_name;
    }
  },
  async created () {
    try {
      const { data: user } = await axios.get('/api/users/me');
      // TODO: Should be removed since there's no access control on this. Leaving it here
      // for debugging for now.
      const { data: subscriptions } = await axios.get('/api/eventsub/subscriptions');
      const userRoles = user.roles || [];

      // TODO: Move this into a navigation guard.
      if (!userRoles.includes('beta')) this.$router.push('/signup');

      this.subscriptions = subscriptions.data;
      this.user = user;
    } catch (err) {
      this.error = 'failed to fetch user information';
    } finally {
      this.loading = false;
    }
  }
};

</script>

<style lang="less" scoped>
.page-home {
  max-width: 600px;

  .profile-pic {
    height: 100%;
    border-radius: 50%;
    border: solid 2px black;
    margin: 10px;
  }

  .greeting {
    margin-bottom: 40px;

    &--header {
      height: 50px;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
