// @see https://dev.twitch.tv/docs/eventsub/manage-subscriptions/
// @see https://dev.twitch.tv/docs/eventsub/eventsub-subscription-types/
// @see https://dev.twitch.tv/docs/eventsub/eventsub-reference/#conditions

// TODO: I think we can get away with a proxy that uses the broadcaster ID from
// the session for most of these. For those where we need more, let's filter
// them out for now and start with only those that require broadcaster ID.

// Format: When __event__ happens I want to __do thing__(context)
// things: create DALLE image, etc

const SUPPORTED_EVENTS = [
  'channel.follow',
  'channel.subscribe',
  'channel.cheer',
  'channel.raid',
  'channel.charity_campaign.donate'
];

export default [
  {
    name: 'Channel Update',
    type: 'channel.update',
    version: 1,
    description: 'A broadcaster updates their channel properties e.g., category, title, mature flag, broadcast, or language.'
  },
  {
    name: 'Channel Follow',
    type: 'channel.follow',
    version: 2,
    description: 'A specified channel receives a follow.'
  },
  {
    name: 'Channel Subscribe',
    type: 'channel.subscribe',
    version: 1,
    description: 'A notification when a specified channel receives a subscriber. This does not include resubscribes.'
  },

  {
    name: 'Channel Subscription End',
    type: 'channel.subscription.end',
    version: 1,
    description: 'A notification when a subscription to the specified channel ends.'
  },
  {
    name: 'Channel Subscription Gift',
    type: 'channel.subscription.gift',
    version: 1,
    description: 'A notification when a viewer gives a gift subscription to one or more users in the specified channel.'
  },
  {
    name: 'Channel Subscription Message',
    type: 'channel.subscription.message',
    version: 1,
    description: 'A notification when a user sends a resubscription chat message in a specific channel.'
  },
  {
    name: 'Channel Cheer',
    type: 'channel.cheer',
    version: 1,
    description: 'A user cheers on the specified channel.'
  },
  {
    name: 'Channel Raid',
    type: 'channel.raid',
    version: 1,
    description: 'A broadcaster raids another broadcaster’s channel.'
  },
  {
    name: 'Channel Ban',
    type: 'channel.ban',
    version: 1,
    description: 'A viewer is banned from the specified channel.'
  },
  {
    name: 'Channel Unban',
    type: 'channel.unban',
    version: 1,
    description: 'A viewer is unbanned from the specified channel.'
  },
  {
    name: 'Channel Moderator Add',
    type: 'channel.moderator.add',
    version: 1,
    description: 'Moderator privileges were added to a user on a specified channel.'
  },
  {
    name: 'Channel Moderator Remove',
    type: 'channel.moderator.remove',
    version: 1,
    description: 'Moderator privileges were removed from a user on a specified channel.'
  },

  {
    name: 'Channel Points Custom Reward Add',
    type: 'channel.channel_points_custom_reward.add',
    version: 1,
    description: 'A custom channel points reward has been created for the specified channel.'
  },
  {
    name: 'Channel Points Custom Reward Update',
    type: 'channel.channel_points_custom_reward.update',
    version: 1,
    description: 'A custom channel points reward has been updated for the specified channel.'
  },
  {
    name: 'Channel Points Custom Reward Remove',
    type: 'channel.channel_points_custom_reward.remove',
    version: 1,
    description: 'A custom channel points reward has been removed from the specified channel.'
  },
  {
    name: 'Channel Points Custom Reward Redemption Add',
    type: 'channel.channel_points_custom_reward_redemption.add',
    version: 1,
    description: 'A viewer has redeemed a custom channel points reward on the specified channel.'
  },
  {
    name: 'Channel Points Custom Reward Redemption Update',
    type: 'channel.channel_points_custom_reward_redemption.update',
    version: 1,
    description: 'A redemption of a channel points custom reward has been updated for the specified channel.'
  },
  {
    name: 'Channel Poll Begin',
    type: 'channel.poll.begin',
    version: 1,
    description: 'A poll started on a specified channel.'
  },
  {
    name: 'Channel Poll Progress',
    type: 'channel.poll.progress',
    version: 1,
    description: 'Users respond to a poll on a specified channel.'
  },
  {
    name: 'Channel Poll End',
    type: 'channel.poll.end',
    version: 1,
    description: 'A poll ended on a specified channel.'
  },
  {
    name: 'Channel Prediction Begin',
    type: 'channel.prediction.begin',
    version: 1,
    description: 'A Prediction started on a specified channel.'
  },
  {
    name: 'Channel Prediction Progress',
    type: 'channel.prediction.progress',
    version: 1,
    description: 'Users participated in a Prediction on a specified channel.'
  },
  {
    name: 'Channel Prediction Lock',
    type: 'channel.prediction.lock',
    version: 1,
    description: 'A Prediction was locked on a specified channel.'
  },
  {
    name: 'Channel Prediction End',
    type: 'channel.prediction.end',
    version: 1,
    description: 'A Prediction ended on a specified channel.'
  },
  {
    name: 'Charity Donation',
    type: 'channel.charity_campaign.donate',
    version: 1,
    description: 'Sends an event notification when a user donates to the broadcaster’s charity campaign.'
  },
  {
    name: 'Charity Campaign Start',
    type: 'channel.charity_campaign.start',
    version: 1,
    description: 'Sends an event notification when the broadcaster starts a charity campaign.'
  },
  {
    name: 'Charity Campaign Progress',
    type: 'channel.charity_campaign.progress',
    version: 1,
    description: 'Sends an event notification when progress is made towards the campaign’s goal or when the broadcaster changes the fundraising goal.'
  },
  {
    name: 'Charity Campaign Stop',
    type: 'channel.charity_campaign.stop',
    version: 1,
    description: 'Sends an event notification when the broadcaster stops a charity campaign.'
  },
  {
    name: 'Drop Entitlement Grant',
    type: 'drop.entitlement.grant',
    version: 1,
    description: 'An entitlement for a Drop is granted to a user.'
  },
  {
    name: 'Extension Bits Transaction Create',
    type: 'extension.bits_transaction.create',
    version: 1,
    description: 'A Bits transaction occurred for a specified Twitch Extension.'
  },
  {
    name: 'Goal Begin',
    type: 'channel.goal.begin',
    version: 1,
    description: 'Get notified when a broadcaster begins a goal.'
  },
  {
    name: 'Goal Progress',
    type: 'channel.goal.progress',
    version: 1,
    description: 'Get notified when progress (either positive or negative) is made towards a broadcaster’s goal.'
  },
  {
    name: 'Goal End',
    type: 'channel.goal.end',
    version: 1,
    description: 'Get notified when a broadcaster ends a goal.'
  },
  {
    name: 'Hype Train Begin',
    type: 'channel.hype_train.begin',
    version: 1,
    description: 'A Hype Train begins on the specified channel.'
  },
  {
    name: 'Hype Train Progress',
    type: 'channel.hype_train.progress',
    version: 1,
    description: 'A Hype Train makes progress on the specified channel.'
  },
  {
    name: 'Hype Train End',
    type: 'channel.hype_train.end',
    version: 1,
    description: 'A Hype Train ends on the specified channel.'
  },
  {
    name: 'Shield Mode Begin',
    type: 'channel.shield_mode.begin',
    version: 1,
    description: 'Sends a notification when the broadcaster activates Shield Mode.'
  },
  {
    name: 'Shield Mode End',
    type: 'channel.shield_mode.end',
    version: 1,
    description: 'Sends a notification when the broadcaster deactivates Shield Mode.'
  },
  {
    name: 'Shoutout Create',
    type: 'channel.shoutout.create',
    version: 1,
    description: 'Sends a notification when the specified broadcaster sends a Shoutout.'
  },
  {
    name: 'Shoutout Received',
    type: 'channel.shoutout.receive',
    version: 1,
    description: 'Sends a notification when the specified broadcaster receives a Shoutout.'
  },
  {
    name: 'Stream Online',
    type: 'stream.online',
    version: 1,
    description: 'The specified broadcaster starts a stream.'
  },
  {
    name: 'Stream Offline',
    type: 'stream.offline',
    version: 1,
    description: 'The specified broadcaster stops a stream.'
  },
  {
    name: 'User Authorization Grant',
    type: 'user.authorization.grant',
    version: 1,
    description: 'A user’s authorization has been granted to your client id.'
  },
  {
    name: 'User Authorization Revoke',
    type: 'user.authorization.revoke',
    version: 1,
    description: 'A user’s authorization has been revoked for your client id.'
  },
  {
    name: 'User Update',
    type: 'user.update',
    version: 1,
    description: 'A user has updated their account.'
  }
].filter(({ type }) => SUPPORTED_EVENTS.includes(type));
