<template>
  <div class="page-emotes">
    <emotes-list />
  </div>
</template>

<script>
import EmotesList from '../components/emotes-list.vue';

export default {
  components: {
    EmotesList
  }
};
</script>

<style lang="less" scoped>

</style>
