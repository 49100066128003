<template>
  <div class="page-signup">
    <div class="greeting">
      <div class="greeting--header">
        Insiders only 🙅‍♂️
      </div>
      <p>
        This app is still a work in progress but if you'd like to help test it out,
        <a
          href="https://ko-fi.com/jzimz/tiers"
          target="_blank"
        >subscribe to my Ko-fi page</a> at the <strong>Insiders</strong> tier to
        join the testers group!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSignup'
};
</script>

<style lang="less" scoped>
.page-signup {
  max-width: 600px;

  .profile-pic {
    border-radius: 50%;
    border: solid 2px black;
    margin: 10px;
  }

  .greeting {
    margin-bottom: 40px;

    &--header {
      height: 50px;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
