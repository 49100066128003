<template>
  <div
    v-if="loading"
    class="emotes"
  >
    Getting emotes...
  </div>
  <div
    v-else
    data-size=""
    class="emotes"
  >
    <div
      v-for="({ _id, url, createdByUserName }) in emotes"
      :key="_id"
      class="emote"
    >
      <img :src="url">
      <span class="username">{{ createdByUserName }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EmotesList',
  data () {
    return {
      loading: true,
      size: 'large',
      emotes: []
    };
  },
  async created () {
    this.loading = true;

    const { data: emotes } = await axios.get('/api/emotes');
    this.emotes = emotes;
    this.loading = false;
  }
};
</script>

<style lang="less" scoped>
.emotes {
  max-width: 600px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  // Twitch emote sizes are 28 x 28px, 56 x 56px and 112 x 112px
  .emote {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex;

    img {
      height: 112px;
      width: 112px;
      border: solid 2px black;
      filter: drop-shadow(0 0 0.2rem black);
      border-radius: 4px;
    }

    .username {
      font-weight: bold;
      font-size: 12px;
    }
  }
}
</style>
