<template>
  <div class="action-form">
    <form>
      On
      <select v-model="formValues.service">
        <option value="twitch">
          Twitch
        </option>
        <option
          disabled
          value="youtube"
        >
          YouTube
        </option>
        <option
          disabled
          value="kofi"
        >
          Ko-Fi
        </option>
      </select>,
      whenever I receive a
      <select v-model="formValues.trigger">
        <option
          v-for="trigger in triggers"
          :key="trigger.type"
          :value="trigger.type"
        >
          {{ trigger.name }}
        </option>
      </select>
      I want to
      <select v-model="formValues.actions">
        <option value="generateEmote">
          generate an emote
        </option>
        <option
          disabled
          value="twitter:tweet"
        >
          send a tweet
        </option>
        <option
          disabled
          value="obs:scene"
        >
          enable scene in OBS
        </option>
        <option
          disabled
          value="discord:send.message"
        >
          send a discord message
        </option>
      </select>
    </form>
    <button @click="handleSubmit">
      Add Hook
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import TwitchEvents from '../../util/twitch-events.js';

export default {
  emits: ['submitted'],
  data () {
    return {
      formValues: {

      }
    };
  },
  computed: {
    triggers () {
      // TODO: This will eventually choose triggers based on the selected
      // service but for now only Twitch is supported.

      return TwitchEvents;
    }
  },
  created () {
    this.formValues = {
      service: 'twitch',
      trigger: this.triggers[0]?.type,
      actions: 'generateEmote'
    };
  },
  methods: {
    async handleSubmit () {
      // TODO: Need to add some "submitting" state here. I also don't know yet
      // if the action-list should be dealing with this.
      await axios.post('/api/actions', {
        ...this.formValues,
        // TODO: Add support for pipeline actions where the result of the
        // previous feeds into the following.
        pipeline: [{
          name: this.formValues.actions
        }]
      });

      this.$emit('submitted');
    }
  }
};
</script>

<style lang="less" scoped>
.action-form {
  form {
    margin-bottom: 10px;

    select {
      font-weight: 700;
      border: none;
    }
  }
}

</style>
