import { createRouter, createWebHistory } from 'vue-router';

import PageHome from './pages/page-home.vue';
import PageLogin from './pages/page-login.vue';
import PageSignup from './pages/page-signup.vue';
import PageEmotes from './pages/page-emotes.vue';

const routes = [
  {
    path: '/',
    component: PageHome,
    meta: {
      requiresAuth: true,
      requiresRole: 'beta'
    }
  },
  {
    path: '/emotes',
    component: PageEmotes,
    meta: {
      requiresAuth: true,
      requiresRole: 'beta'
    }
  },
  {
    path: '/signup',
    component: PageSignup
  },
  {
    path: '/login',
    component: PageLogin
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
