<template>
  <div class="actions-list">
    <action-form
      class="actions-list--form"
      @submitted="fetchActions"
    />
    <div v-if="loading">
      Looking up your actions...
    </div>
    <div v-else-if="actions.length">
      <div
        v-for="({ _id, service, trigger, pipeline }) in actions"
        :key="_id"
        class="actions-list--item"
      >
        {{ service }} &#8594; {{ trigger }} &#8594;
        {{ pipeline.map(({ name }) => name).join(', ') }}
        <button @click="handleRemove(_id)">
          Remove
        </button>
      </div>
    </div>
    <div v-else>
      You haven't added any stream actions yet.
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ActionForm from './action-form.vue';

export default {
  name: 'ActionsList',
  components: {
    ActionForm
  },
  data () {
    return {
      loading: false,
      actions: []
    };
  },
  async created () {
    this.fetchActions();
  },
  methods: {
    async fetchActions () {
      this.loading = true;

      const { data: actions } = await axios.get('/api/actions');

      this.actions = actions;
      this.loading = false;
    },
    async handleRemove (id) {
      await axios.delete(`/api/actions/${id}`);
      await this.fetchActions();
    }
  }
};
</script>

<style lang="less" scoped>
.actions-list {
  &--form {
    margin-bottom: 40px;
  }

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
