<template>
  <div class="page-login">
    <h1 class="greeting">
      Hey 👋
    </h1>
    <p class="description">
      Welcome to <strong>Stream Hooks</strong>, the easiest way to do things based on Twitch events.
    </p>
    <p>
      This app is still under development but you can join the waitlist by logging in with your Twitch account.
    </p>
    <button
      :disabled="loading"
      class="login-btn"
      @click="handleLogin"
    >
      <img
        class="service-icon"
        src="../assets/TwitchGlitchBlackOps.svg"
        alt="Twitch Logo"
      >
      {{ loginText }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'PageLogin',
  data () {
    return {
      loginText: 'Log in with Twitch',
      loading: false
    };
  },
  methods: {
    handleLogin () {
      if (this.loading) return;

      this.loading = true;
      this.loginText = 'Working on it...';
      window.location.href = '/api/auth';
    }
  }
};
</script>
<style lang="less" scoped>

.page-login {
  height: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .greeting,
  .description {
    margin: 0;
  }

  .login-btn {
    border: solid 2px black;
    border-radius: 4px;
    padding: 10px;
    background-color: transparent;
    transition: background-color .25s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:hover {
      cursor: pointer;
      background-color: lighten(black, 85%);
    }
    &[disabled] {
      border-color: lighten(black, 60%);

      .service-icon {
        display: none;
      }
    }
    .service-icon {
      height: 20px;
    }
  }
}
</style>
