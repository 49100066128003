<template>
  <div class="app-container">
    <div class="app-container--body">
      <router-view />
    </div>
    <footer class="app-container--footer">
      Built with 💜 by JZimz. <br>
      <a href="https://ko-fi.com/jzimz">Donate on Ko-fi</a> to support the project!
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App'
};
</script>

<style lang="less" scoped>
  .app-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--body {
      flex-grow: 1;
    }

    &--footer {
      font-size: 12px;
      text-align: center;
    }
  }
</style>
